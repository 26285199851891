import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
    {
        path: 'mission/:id',
        loadChildren: () => import('./mission/mission.module').then(m => m.MissionPageModule)
    },
    {
        path: 'mission-start/:id',
        loadChildren: () => import('./mission-start/mission-start.module').then(m => m.MissionStartPageModule)
    },
    {
        path: 'mission-report/:id',
        loadChildren: () => import('./mission-report/mission-report.module').then(m => m.MissionReportPageModule)
    },
    {
        path: 'mission-end/:id',
        loadChildren: () => import('./mission-end/mission-end.module').then(m => m.MissionEndPageModule)
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
