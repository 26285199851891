import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {Device} from '@ionic-native/device/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {Zip} from '@ionic-native/zip/ngx';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {NativeGeocoder} from '@ionic-native/native-geocoder/ngx';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import {ModalController} from '@ionic/angular';

export function LanguageLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [

    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ZXingScannerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (LanguageLoader),
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        StatusBar,
        Device,
        Camera,
        PhotoViewer,
        Zip,
        FileTransfer,
        BarcodeScanner,
        Geolocation,
        ModalController,
        NativeGeocoder,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
