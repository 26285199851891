import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {ApiConnectionService} from './api-connection.service';
import {Environment} from '@ionic-native/google-maps';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private statusBar: StatusBar,
        private apiConnection: ApiConnectionService,
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            Environment.setEnv({
                // Api key for your server
                // (Make sure the api key should have Website restrictions for your website domain only)
                'API_KEY_FOR_BROWSER_RELEASE': 'AIzaSyBsbITlVfN9tKmh2cTskSd5yilCcj_y6oY',
                // Api key for local development
                // (Make sure the api key should have Website restrictions for 'http://localhost' only)
                'API_KEY_FOR_BROWSER_DEBUG': 'AIzaSyBsbITlVfN9tKmh2cTskSd5yilCcj_y6oY'
            });
            console.log('env set');
            this.statusBar.styleDefault();
            this.postToken();
        });
    }

    postToken() {
        this.apiConnection.connect().forEach((token) => {
          //  console.log(token);
        });
    }

}

