import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {NavController, Platform} from '@ionic/angular';
import {catchError, map} from 'rxjs/operators';
import {SERVER_URL} from '../environments/environment';
import {Device} from '@ionic-native/device/ngx';
import {Zip} from '@ionic-native/zip/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';

@Injectable({
    providedIn: 'root'
})
export class ApiConnectionService {
    data: any = null;
    apiPath: string = SERVER_URL;
    uuid: string = null;
    appName = 'geostreet_';
    token: string = null;
    refreshToken: string = null;
    gameId: number = null;
    language = 'fr';
    me: any;
    path: string;
    params: any;
    secured: boolean;
    method: string;
    cities: any;
    toRelaunch: boolean;
    public storage: string;

    constructor(private transfer: FileTransfer,
                private zip: Zip,
                private http: HttpClient,
                private platform: Platform,
                private device: Device,
                private navCtrl: NavController) {
        this.reloadToken();
    }

    reloadToken() {
        this.uuid = window.localStorage.getItem(this.appName + 'uuid');
    }

    clearToken() {
        this.uuid = null;
        window.localStorage.removeItem(this.appName + 'uuid');
    }

    connect() {
        return new Observable((responseObserver) => {
            this.token = window.localStorage.getItem(this.appName + 'token');
            this.refreshToken = window.localStorage.getItem(this.appName + 'refreshToken');
            this.uuid = window.localStorage.getItem(this.appName + 'uuid');
            /* Okay, so the platform is ready and our plugins are available.
             Here you can do any higher level native things you might need.*/
            let uuid = null;
            if (this.platform.is('cordova')) {
                /* this.apiPath = this.prodApiPath;*/
                uuid = this.device.uuid;
            }
            if (!uuid) {
                uuid = window.localStorage.getItem(this.appName + 'uuid');
                if (!uuid) {
                    uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                        return v.toString(16);
                    });
                }
            }

            window.localStorage.setItem(this.appName + 'uuid', uuid);

            this.uuid = uuid;
            responseObserver.next({
                uuid: this.uuid
            });
        });
    }


    getUuid() {
        return this.uuid;
    }

    serialize(obj, prefix) {
        const str = [];
        for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
                const k = prefix ? prefix + '[' + p + ']' : p, v = obj[p];
                str.push(typeof v === 'object' ?
                    this.serialize(v, k) :
                    encodeURIComponent(k) + '=' + encodeURIComponent(v));
            }
        }
        return str.join('&');
    }

    requestError(param) {
    }

    securedPost(path, params, headers, secured) {
        return this.http.post(
            path,
            params,
            headers
        ).pipe(catchError(initialError => {
            if (initialError && initialError.status === 401) {
                let body = initialError.error;
                if (body.message === 'Invalid JWT Token' || body.message == 'Expired JWT Token') {
                    this.path = path;
                    this.method = 'post';
                    this.params = params;
                    this.secured = secured;
                } else {
                    return throwError(initialError);
                }
            } else {
                return throwError(initialError);
            }
        }));
    }


    post(path, params, security = true, success = null, error = null) {
        this.reloadToken();
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        if (security) {
            this.connect().forEach(() => {
                headers = headers.append('Authorization', 'Bearer ' + this.token);
            });
        }

        return this.securedPost(
            this.apiPath + path,
            this.serialize(params, null),
            {headers: headers},
            security
        )
            .pipe(map(res => {
                return res;
            })).subscribe(
                success,
                error ? error : this.requestError.bind(this),
                () => {
                }
            );
    }

    securedPut(path, params, headers, secured) {
        return this.http.put(
            path,
            params,
            headers
        ).pipe(catchError(initialError => {
            let body = initialError.error;
            if (initialError && initialError.status === 401) {
                if (body.message == 'Invalid JWT Token' || body.message === 'Expired JWT Token') {
                    this.path = path;
                    this.method = 'put';
                    this.params = params;
                    this.secured = secured;
                } else {
                    return throwError(initialError);
                }
            } else {
                return throwError(initialError);
            }
        }));
    }

    put(path, params, security = true, success = null, error = null) {
        this.reloadToken();
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

        if (security) {
            headers = headers.append('Authorization', 'Bearer ' + this.token);
        }

        return this.securedPut(
            this.apiPath + path,
            this.serialize(params, null),
            {headers: headers, withCredentials: true},
            security
        )
            .pipe(map(res => {
                return res;
            })).subscribe(
                success,
                error ? error : this.requestError.bind(this),
                () => {
                }
            );
    }

    securedGet(path, headers, secured) {
        // token might be expired, try to refresh token
        return this.http.get(
            path,
            headers
        ).pipe(catchError(initialError => {
            if (initialError && initialError.status === 401) {
                let body = initialError.error;
                if (body.message === 'Invalid JWT Token' || body.message === 'Expired JWT Token') {
                    this.path = path;
                    this.method = 'get';
                    this.secured = secured;
                } else {
                    return throwError(initialError);
                }
            } else {
                return throwError(initialError);
            }
        }));
    }

    relaunch(headers) {
        this.toRelaunch = false;
        if (this.method === 'get') {
            return this.http.get(this.path, {headers: headers});
        } else if (this.method === 'put') {
            return this.http.put(this.path, this.params, {headers: headers});
        } else if (this.method === 'delete') {
            return this.http.delete(this.path, {headers: headers});
        } else if (this.method === 'post') {
            return this.http.post(this.path, this.params, {headers: headers});
        }
    }


    get(path: string, params = null, security = true, success = null, error = null) {
        this.reloadToken();
        let headers = new HttpHeaders();
        if (security) {
            headers = headers.append('Authorization', 'Bearer ' + this.token);
        }
        path = this.apiPath + path;
        if (params) {
            path += '?' + this.serialize(params, null);
        }
        return this.securedGet(
            path,
            {headers: headers},
            security
        )
            .pipe(map(res => {
                return res;
            })).subscribe(
                success,
                error ? error : this.requestError.bind(this),
                () => {
                }
            );
    }
}
